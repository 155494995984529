import { autoserializeAs } from 'dcerialize';

/**
 * EVA API response data for field MoodleParams
 */
export class MoodleParams {
  @autoserializeAs(() => String) id: string;
  @autoserializeAs(() => String, 'lti_message_type') ltiMessageType: string;
  @autoserializeAs(() => String, 'lti_version') ltiVersion: string;
  @autoserializeAs(() => String, 'oauth_consumer_key') oauthConsumerKey: string;
  @autoserializeAs(() => String, 'oauth_nonce') oauthNonce: string;
  @autoserializeAs(() => String, 'oauth_signature') oauthSignature: string;
  @autoserializeAs(() => String, 'oauth_signature_method') oauthSignatureMethod: string;
  @autoserializeAs(() => String, 'oauth_timestamp') oauthTimestamp: string;
  @autoserializeAs(() => String, 'oauth_version') oauthVersion: string;
  @autoserializeAs(() => String, 'resource_link_id') resourceLinkId: string;
  @autoserializeAs(() => String, 'user_id') userId: string;
  @autoserializeAs(() => String, 'lis_person_name_full') lisPersonNameFull: string;
  @autoserializeAs(() => String, 'lis_person_contact_email_primary') lisPersonContactEmailPrimary: string;

  constructor(
    id = '',
    ltiMessageType = '',
    ltiVersion = '',
    oauthConsumerKey = '',
    oauthNonce = '',
    oauthSignature = '',
    oauthSignatureMethod = '',
    oauthTimestamp = '',
    oauthVersion = '',
    resourceLinkId = '',
    userId = '',
    lisPersonNameFull = '',
    lisPersonContactEmailPrimary = ''
  ) {
    this.id = id;
    this.ltiMessageType = ltiMessageType;
    this.ltiVersion = ltiVersion;
    this.oauthConsumerKey = oauthConsumerKey;
    this.oauthNonce = oauthNonce;
    this.oauthSignature = oauthSignature;
    this.oauthSignatureMethod = oauthSignatureMethod;
    this.oauthTimestamp = oauthTimestamp;
    this.oauthVersion = oauthVersion;
    this.resourceLinkId = resourceLinkId;
    this.userId = userId;
    this.lisPersonNameFull = lisPersonNameFull;
    this.lisPersonContactEmailPrimary = lisPersonContactEmailPrimary;
  }
}

/**
 * EVA API response
 */
export class MoodleFrame {
  @autoserializeAs(() => String) urlEnrollment: string;
  @autoserializeAs(() => MoodleParams) params: MoodleParams;
  @autoserializeAs(() => String) method: string;

  constructor(urlEnrollment = '', params: MoodleParams, method = '') {
    this.urlEnrollment = urlEnrollment;
    this.params = params;
    this.method = method;
  }
}
